"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionExpiry = void 0;
const util_1 = require("../util");
/**
 * Representation of a transaction expiry date.
 *
 * A transaction expiry has to be in the future. Note that the concordium-node
 * will reject transactions that are too far into the future, currently the default
 * value for this rejection is 2 hours.
 */
class TransactionExpiry {
    constructor(expiry, allowExpired = false) {
        if (!allowExpired && expiry < new Date()) {
            throw new Error('A transaction expiry is not allowed to be in the past: ' +
                expiry);
        }
        this.expiryEpochSeconds = (0, util_1.secondsSinceEpoch)(expiry);
    }
    static fromEpochSeconds(seconds, allowExpired = false) {
        return new TransactionExpiry(new Date(Number(seconds) * 1000), allowExpired);
    }
    toJSON() {
        return Number(this.expiryEpochSeconds);
    }
}
exports.TransactionExpiry = TransactionExpiry;
