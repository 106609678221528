"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleReference = void 0;
const buffer_1 = require("buffer/");
const serializationHelpers_1 = require("../serializationHelpers");
/**
 * Representation of a module reference, which enforces that it:
 * - Hash length exactly 64
 * - Is a valid 64 length hex string
 */
class ModuleReference {
    constructor(moduleRef) {
        if (moduleRef.length !== 64) {
            throw new Error('The provided moduleRef ' +
                moduleRef +
                ' is invalid as its length was not 64');
        }
        try {
            this.decodedModuleRef = buffer_1.Buffer.from(moduleRef, 'hex');
            this.moduleRef = moduleRef;
        }
        catch (error) {
            throw error;
        }
    }
    toJSON() {
        return (0, serializationHelpers_1.packBufferWithWord32Length)(buffer_1.Buffer.from(this.decodedModuleRef)).toString('hex');
    }
}
exports.ModuleReference = ModuleReference;
