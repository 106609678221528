"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isBlockSummaryV0 = exports.isBlockSummaryV1 = exports.isUpdatesV0 = exports.isUpdatesV1 = exports.isUpdateQueuesV0 = exports.isUpdateQueuesV1 = exports.isKeysV0 = exports.isKeysV1 = exports.isChainParametersV0 = exports.isChainParametersV1 = exports.isAuthorizationsV1 = void 0;
const isAuthorizationsV1 = (a) => a.timeParameters !== undefined;
exports.isAuthorizationsV1 = isAuthorizationsV1;
const isChainParametersV1 = (cp) => cp.mintPerPayday !== undefined;
exports.isChainParametersV1 = isChainParametersV1;
const isChainParametersV0 = (cp) => cp.minimumThresholdForBaking !== undefined;
exports.isChainParametersV0 = isChainParametersV0;
const isKeysV1 = (k) => (0, exports.isAuthorizationsV1)(k.level2Keys);
exports.isKeysV1 = isKeysV1;
const isKeysV0 = (k) => !(0, exports.isAuthorizationsV1)(k.level2Keys);
exports.isKeysV0 = isKeysV0;
const isUpdateQueuesV1 = (uq) => uq.timeParameters !== undefined;
exports.isUpdateQueuesV1 = isUpdateQueuesV1;
const isUpdateQueuesV0 = (uq) => uq.bakerStakeThreshold !== undefined;
exports.isUpdateQueuesV0 = isUpdateQueuesV0;
const isUpdatesV1 = (u) => (0, exports.isUpdateQueuesV1)(u.updateQueues);
exports.isUpdatesV1 = isUpdatesV1;
const isUpdatesV0 = (u) => (0, exports.isUpdateQueuesV0)(u.updateQueues);
exports.isUpdatesV0 = isUpdatesV0;
const isBlockSummaryV1 = (bs) => bs.protocolVersion !== undefined && bs.protocolVersion > 3n;
exports.isBlockSummaryV1 = isBlockSummaryV1;
const isBlockSummaryV0 = (bs) => bs.protocolVersion === undefined || bs.protocolVersion <= 3n;
exports.isBlockSummaryV0 = isBlockSummaryV0;
