"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.verifyMessageSignature = exports.signMessage = exports.signTransaction = exports.buildBasicAccountSigner = void 0;
const serialization_1 = require("./serialization");
const ed = __importStar(require("@noble/ed25519"));
const buffer_1 = require("buffer/");
const accountAddress_1 = require("./types/accountAddress");
const hash_1 = require("./hash");
/**
 * Creates a signer for an account which uses the first credential's first keypair.
 * Note that if the account has a threshold > 1 or the first credentials has a threshold > 1, the transaction signed using this will fail.
 * @param privateKey the ed25519 private key in HEX format. (First credential's first keypair's private key)
 */
function buildBasicAccountSigner(privateKey) {
    return {
        getSignatureCount() {
            return 1n;
        },
        async sign(digest) {
            const signature = buffer_1.Buffer.from(await ed.sign(digest, privateKey)).toString('hex');
            return {
                0: {
                    0: signature,
                },
            };
        },
    };
}
exports.buildBasicAccountSigner = buildBasicAccountSigner;
/**
 * Helper function to sign an AccountTransaction.
 * @param transaction the account transaction to sign
 * @param signer An object that handles the keys of the account, and performs the actual signing.
 */
function signTransaction(transaction, signer) {
    const digest = (0, serialization_1.getAccountTransactionSignDigest)(transaction, signer.getSignatureCount());
    return signer.sign(digest);
}
exports.signTransaction = signTransaction;
/**
 * @param account the address of the account that will sign this message.
 * @param message the message to sign, assumed to be utf8 encoded.
 */
function getMessageDigest(account, message) {
    const prepend = buffer_1.Buffer.alloc(8, 0);
    const rawMessage = buffer_1.Buffer.from(message, 'utf8');
    return (0, hash_1.sha256)([account.decodedAddress, prepend, rawMessage]);
}
/**
 * Helper function to sign a message.
 * Note that this function prepends the string "MyGoodPrepend" to ensure that the message is not a transaction.
 * Note that the current prepend is temporary and will later be replaced.
 * @param message the message to sign, assumed to be utf8 encoded.
 * @param signer An object that handles the keys of the account, and performs the actual signing.
 */
function signMessage(account, message, signer) {
    return signer.sign(getMessageDigest(account, message));
}
exports.signMessage = signMessage;
/**
 * Helper function to verify a signed message.
 * @param message the message to sign, assumed to be utf8 encoded.
 * @param signature the signature of a message, from a specific account.
 * @param accountInfo the address and credentials of the account
 */
async function verifyMessageSignature(message, signature, accountInfo) {
    if (Object.keys(signature).length < accountInfo.accountThreshold) {
        // Not enough credentials have signed;
        return false;
    }
    const digest = getMessageDigest(new accountAddress_1.AccountAddress(accountInfo.accountAddress), message);
    for (const credentialIndex of Object.keys(signature)) {
        const credential = accountInfo.accountCredentials[Number(credentialIndex)];
        if (!credential) {
            throw new Error('Signature contains signature for non-existing credential');
        }
        const credentialSignature = signature[Number(credentialIndex)];
        const credentialKeys = credential.value.contents.credentialPublicKeys;
        if (Object.keys(credentialSignature).length < credentialKeys.threshold) {
            // Not enough signatures for the current credential;
            return false;
        }
        for (const keyIndex of Object.keys(credentialSignature)) {
            if (!credentialKeys.keys[Number(keyIndex)]) {
                throw new Error('Signature contains signature for non-existing keyIndex');
            }
            if (!(await ed.verify(credentialSignature[Number(keyIndex)], digest, credentialKeys.keys[Number(keyIndex)].verifyKey))) {
                // Incorrect signature;
                return false;
            }
        }
    }
    return true;
}
exports.verifyMessageSignature = verifyMessageSignature;
