"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.attributesWithSet = exports.attributesWithRange = exports.StatementTypes = void 0;
var StatementTypes;
(function (StatementTypes) {
    StatementTypes["RevealAttribute"] = "RevealAttribute";
    StatementTypes["AttributeInSet"] = "AttributeInSet";
    StatementTypes["AttributeNotInSet"] = "AttributeNotInSet";
    StatementTypes["AttributeInRange"] = "AttributeInRange";
})(StatementTypes = exports.StatementTypes || (exports.StatementTypes = {}));
/**
 * The attributes that can be used for range statements
 */
exports.attributesWithRange = [
    'dob',
    'idDocIssuedAt',
    'idDocExpiresAt',
];
/**
 * The attributes that can be used for (non)membership statements
 */
exports.attributesWithSet = [
    'countryOfResidence',
    'nationality',
    'idDocType',
    'idDocIssuer',
];
