"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAlias = exports.isAlias = void 0;
const bs58check = __importStar(require("bs58check"));
const buffer_1 = require("buffer/");
const accountAddress_1 = require("./types/accountAddress");
const addressByteLength = 32;
const aliasBytesLength = 3;
const commonBytesLength = addressByteLength - aliasBytesLength;
const maxCount = 16777215; // 2^(8 * 3) - 1
/**
 * Given two accountAddresses, return whether they are aliases.
 * @param address an AccountAddress
 * @param alias another AccountAddress
 * @returns boolean that indicates whether address and alias are aliases
 */
function isAlias(address, alias) {
    return (0 ===
        address.decodedAddress.compare(alias.decodedAddress, 0, commonBytesLength, 0, commonBytesLength));
}
exports.isAlias = isAlias;
/**
 * Given an AccountAddress and a counter, returns an alias for the address.
 * @param address the account address for which the function should get an alias for
 * @param counter number s.t. 0 <= counter < 2^24, decides which alias is returned.
 * If a counter outside this scope is given, then the function will throw an exception
 * @returns an AccountAddress, which is an alias to the given address
 */
function getAlias(address, counter) {
    if (counter < 0 || counter > maxCount) {
        throw new Error(`An invalid counter value was given: ${counter}. The value has to satisfy that 0 <= counter < 2^24`);
    }
    const commonBytes = address.decodedAddress.slice(0, commonBytesLength);
    const aliasBytes = buffer_1.Buffer.alloc(aliasBytesLength);
    aliasBytes.writeUIntBE(counter, 0, aliasBytesLength);
    const prefixedWithVersion = buffer_1.Buffer.concat([
        buffer_1.Buffer.of(1),
        commonBytes,
        aliasBytes,
    ]);
    return new accountAddress_1.AccountAddress(bs58check.encode(prefixedWithVersion));
}
exports.getAlias = getAlias;
