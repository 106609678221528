"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deserialModuleFromBuffer = exports.deserialUint8 = exports.deserialTupleFn = exports.deserialOptionFn = exports.OptionTag = exports.deserialMapFn = exports.deserialArrayFn = exports.deserialString = exports.deserialFields = exports.FieldsTag = exports.deserialType = exports.deserialFunctionV2 = exports.deserialFunctionV1 = exports.SizeLength = exports.deserialContractV2 = exports.deserialContractV1 = exports.deserialContractV0 = exports.deserialVersionedModule = exports.VERSIONED_SCHEMA_PREFIX = exports.deserialModuleV2 = exports.deserialModuleV1 = exports.deserialModuleV0 = void 0;
const stream_1 = require("stream");
const types_1 = require("./types");
const buffer_1 = require("buffer/");
/**
 * Reads the schema (V0) for a contract module from the given {@link Readable}.
 *
 * @param source input stream
 * @returns schema (V0) of a module (contract map)
 */
function deserialModuleV0(source) {
    return deserialMapFn(deserialString, deserialContractV0)(source);
}
exports.deserialModuleV0 = deserialModuleV0;
/**
 *  Reads the schema (V1) for a contract module from the given{@link Readable}.
 *
 * @param source input stream
 * @returns schema (V1) of a module (contract map)
 */
function deserialModuleV1(source) {
    return deserialMapFn(deserialString, deserialContractV1)(source);
}
exports.deserialModuleV1 = deserialModuleV1;
/**
 *  Reads the schema (V2) for a contract module from the given{@link Readable}.
 *
 * @param source input stream
 * @returns schema (V2) of a module (contract map)
 */
function deserialModuleV2(source) {
    return deserialMapFn(deserialString, deserialContractV2)(source);
}
exports.deserialModuleV2 = deserialModuleV2;
/**
 *  Magic prefix for versioned schemas, used to distinguish between a versioned
 *  schema and the older format without versioning as part of the schema.
 *
 *  The constant is corresponding to two maxed-out bytes, interpreted as a 16-bit unsigned integer.
 */
exports.VERSIONED_SCHEMA_PREFIX = 65535;
/**
 *  Reads a versioned schema for a contract module from the given{@link Readable}.
 *
 * @param source input stream
 * @returns schema (Versioned) of a module (contract map)
 */
function deserialVersionedModule(source) {
    const prefix = deserialUint16(source);
    if (prefix != exports.VERSIONED_SCHEMA_PREFIX) {
        throw new Error('Versioned schema module must be prefixed with two maxed-out bytes');
    }
    const version = deserialUint8(source);
    switch (version) {
        case types_1.SchemaVersion.V0:
            return {
                v: version,
                value: deserialModuleV0(source),
            };
        case types_1.SchemaVersion.V1:
            return {
                v: version,
                value: deserialModuleV1(source),
            };
        case types_1.SchemaVersion.V2:
            return {
                v: version,
                value: deserialModuleV2(source),
            };
        default:
            throw new Error('Unsupported schema version');
    }
}
exports.deserialVersionedModule = deserialVersionedModule;
/**
 * Reads {@link ContractV0} from the given {@link Readable}.
 *
 * @param source input stream
 * @returns schema (V0) for a contract.
 */
function deserialContractV0(source) {
    return {
        state: deserialOptionFn(deserialType)(source),
        init: deserialOptionFn(deserialType)(source),
        receive: deserialMapFn(deserialString, deserialType)(source),
    };
}
exports.deserialContractV0 = deserialContractV0;
/**
 * Reads {@link ContractV1} from the given {@link Readable}.
 *
 * @param source input stream
 * @returns schema (V1) for a contract.
 */
function deserialContractV1(source) {
    return {
        init: deserialOptionFn(deserialFunctionV1)(source),
        receive: deserialMapFn(deserialString, deserialFunctionV1)(source),
    };
}
exports.deserialContractV1 = deserialContractV1;
/**
 * Reads {@link ContractV2} from the given {@link Readable}.
 *
 * @param source input stream
 * @returns schema (V2) for a contract.
 */
function deserialContractV2(source) {
    return {
        init: deserialOptionFn(deserialFunctionV2)(source),
        receive: deserialMapFn(deserialString, deserialFunctionV2)(source),
    };
}
exports.deserialContractV2 = deserialContractV2;
/**
 * Size length of a {@link Type}, represented as an unsigned integer.
 */
var SizeLength;
(function (SizeLength) {
    /** Takes 1 byte and represents a possible size range of 0..255. */
    SizeLength[SizeLength["U8"] = 0] = "U8";
    /** Takes 2 bytes and represents a possible size range of 0..65535. */
    SizeLength[SizeLength["U16"] = 1] = "U16";
    /** Takes 4 bytes and represents a possible size range of 0..4294967295. */
    SizeLength[SizeLength["U32"] = 2] = "U32";
    /** Takes 8 bytes and represents a possible size range of 0..2^64-1. */
    SizeLength[SizeLength["U64"] = 3] = "U64";
})(SizeLength = exports.SizeLength || (exports.SizeLength = {}));
/**
 * The first byte of the function denotes whether the parameters, return value, or both are typed.
 */
var FunctionTag;
(function (FunctionTag) {
    // V1 Functions only use the first three tags
    FunctionTag[FunctionTag["Param"] = 0] = "Param";
    /// Rv is short for Return value.
    FunctionTag[FunctionTag["Rv"] = 1] = "Rv";
    FunctionTag[FunctionTag["ParamRv"] = 2] = "ParamRv";
    FunctionTag[FunctionTag["Error"] = 3] = "Error";
    FunctionTag[FunctionTag["ParamError"] = 4] = "ParamError";
    FunctionTag[FunctionTag["RvError"] = 5] = "RvError";
    FunctionTag[FunctionTag["ParamRvError"] = 6] = "ParamRvError";
})(FunctionTag || (FunctionTag = {}));
/**
 * Reads {@link ContractFunction} from the given {@link Readable}.
 *
 * @param source input stream
 * @returns Function schema type
 */
function deserialFunctionV1(source) {
    const tag = deserialUint8(source);
    switch (tag) {
        case FunctionTag.Param:
            return {
                parameter: deserialType(source),
            };
        case FunctionTag.Rv:
            return {
                returnValue: deserialType(source),
            };
        case FunctionTag.ParamRv:
            return {
                parameter: deserialType(source),
                returnValue: deserialType(source),
            };
        default:
            throw new Error('Incorrect tag for function');
    }
}
exports.deserialFunctionV1 = deserialFunctionV1;
/**
 * Reads {@link ContractFunctionV2} from the given {@link Readable}.
 *
 * @param source input stream
 * @returns Function schema type
 */
function deserialFunctionV2(source) {
    const tag = deserialUint8(source);
    switch (tag) {
        case FunctionTag.Param:
            return {
                parameter: deserialType(source),
            };
        case FunctionTag.Rv:
            return {
                returnValue: deserialType(source),
            };
        case FunctionTag.ParamRv:
            return {
                parameter: deserialType(source),
                returnValue: deserialType(source),
            };
        case FunctionTag.Error:
            return {
                error: deserialType(source),
            };
        case FunctionTag.ParamError:
            return {
                parameter: deserialType(source),
                error: deserialType(source),
            };
        case FunctionTag.RvError:
            return {
                returnValue: deserialType(source),
                error: deserialType(source),
            };
        case FunctionTag.ParamRvError:
            return {
                parameter: deserialType(source),
                returnValue: deserialType(source),
                error: deserialType(source),
            };
        default:
            throw new Error('Incorrect tag for function');
    }
}
exports.deserialFunctionV2 = deserialFunctionV2;
/**
 * Reads {@link Type} from the given {@link Readable}.
 *
 * @param source input stream
 * @returns contract schema type
 */
function deserialType(source) {
    const tag = deserialUint8(source);
    switch (tag) {
        case types_1.ParameterType.Unit:
        case types_1.ParameterType.Bool:
        case types_1.ParameterType.U8:
        case types_1.ParameterType.U16:
        case types_1.ParameterType.U32:
        case types_1.ParameterType.U64:
        case types_1.ParameterType.U128:
        case types_1.ParameterType.I8:
        case types_1.ParameterType.I16:
        case types_1.ParameterType.I32:
        case types_1.ParameterType.I64:
        case types_1.ParameterType.I128:
        case types_1.ParameterType.Amount:
        case types_1.ParameterType.AccountAddress:
        case types_1.ParameterType.ContractAddress:
        case types_1.ParameterType.Timestamp:
        case types_1.ParameterType.Duration:
            return { typeTag: tag };
        case types_1.ParameterType.Pair:
            return {
                typeTag: tag,
                ofLeft: deserialType(source),
                ofRight: deserialType(source),
            };
        case types_1.ParameterType.List:
        case types_1.ParameterType.Set:
            return {
                typeTag: tag,
                sizeLength: deserialUint8(source),
                of: deserialType(source),
            };
        case types_1.ParameterType.Map:
            return {
                typeTag: tag,
                sizeLength: deserialUint8(source),
                ofKeys: deserialType(source),
                ofValues: deserialType(source),
            };
        case types_1.ParameterType.Array:
            return {
                typeTag: tag,
                size: deserialUint32(source),
                of: deserialType(source),
            };
        case types_1.ParameterType.Struct:
            return {
                typeTag: tag,
                fields: deserialFields(source),
            };
        case types_1.ParameterType.Enum:
            return {
                typeTag: tag,
                variants: deserialArrayFn(deserialTupleFn(deserialString, deserialFields))(source),
            };
        case types_1.ParameterType.String:
        case types_1.ParameterType.ContractName:
        case types_1.ParameterType.ReceiveName:
            return {
                typeTag: tag,
                sizeLength: deserialUint8(source),
            };
        case types_1.ParameterType.ULeb128:
            return {
                typeTag: tag,
                constraint: deserialUint32(source),
            };
        case types_1.ParameterType.ILeb128:
            return {
                typeTag: tag,
                constraint: deserialUint32(source),
            };
        case types_1.ParameterType.ByteList:
            return {
                typeTag: tag,
                sizeLength: deserialUint8(source),
            };
        case types_1.ParameterType.ByteArray:
            return {
                typeTag: tag,
                size: deserialUint32(source),
            };
        default:
            throw new Error(`unsupported type tag: ${tag}`);
    }
}
exports.deserialType = deserialType;
/**
 * {@link Fields} tag.
 */
var FieldsTag;
(function (FieldsTag) {
    /**
     * Represents named fields such as in
     * `struct RGB { r: u8, g: u8, b: u8 }`.
     */
    FieldsTag[FieldsTag["Named"] = 0] = "Named";
    /**
     * Represents unnamed (anonymous) struct fields such as in
     * `struct Point { u32, u32 }`.
     */
    FieldsTag[FieldsTag["Unnamed"] = 1] = "Unnamed";
    /**
     * Represents lack of fields in a struct or an enum, as is the case
     * with `Cat` in `enum Animal { Cat, Dog, Human }`.
     */
    FieldsTag[FieldsTag["None"] = 2] = "None";
})(FieldsTag = exports.FieldsTag || (exports.FieldsTag = {}));
/**
 * Reads {@link Fields} from the given {@link Readable}.
 *
 * @param source input stream
 * @returns struct or enum variant fields
 */
function deserialFields(source) {
    const tag = deserialUint8(source);
    switch (tag) {
        case FieldsTag.Named:
            return {
                fieldsTag: tag,
                contents: deserialArrayFn(deserialTupleFn(deserialString, deserialType))(source),
            };
        case FieldsTag.Unnamed:
            return {
                fieldsTag: tag,
                contents: deserialArrayFn(deserialType)(source),
            };
        case FieldsTag.None:
            return { fieldsTag: tag };
        default:
            throw new Error(`unsupported fields tag: ${tag}`);
    }
}
exports.deserialFields = deserialFields;
/**
 * Reads a string from the given {@link Readable}.
 *
 * @param source input stream
 * @returns string
 */
function deserialString(source) {
    const bytes = deserialArrayFn(deserialUint8)(source);
    return buffer_1.Buffer.from(bytes).toString('utf8');
}
exports.deserialString = deserialString;
/**
 * Takes a {@link Deserial} function of the given type and returns another
 * {@link Deserial} function that can read an array of the same type.
 *
 * @typeParam T - {@link Deserial} output type
 * @param deserial function that takes {@link Readable} and returns `T`
 * @returns function that takes {@link Readable} and returns an array of `T`
 */
function deserialArrayFn(deserial) {
    return function (source) {
        const len = deserialUint32(source);
        const arr = [];
        for (let i = 0; i < len; i++) {
            arr.push(deserial(source));
        }
        return arr;
    };
}
exports.deserialArrayFn = deserialArrayFn;
/**
 * Takes two {@link Deserial} functions of two given types: one for keys
 * and the other one for values. Returns another {@link Deserial} function
 * that can read a map with keys and values of corresponding types.
 *
 * @typeParam K - {@link Deserial} output type for keys
 * @typeParam V - {@link Deserial} output type for values
 * @param deserialKey function that takes {@link Readable} and returns `K`
 * @param deserialValue function that takes {@link Readable} and returns `V`
 * @returns function that takes {@link Readable} and returns a map from `K` to `V`
 */
function deserialMapFn(deserialKey, deserialValue) {
    return function (source) {
        const len = deserialUint32(source);
        const obj = {};
        for (let i = 0; i < len; i++) {
            const k = deserialKey(source);
            const v = deserialValue(source);
            obj[k] = v;
        }
        return obj;
    };
}
exports.deserialMapFn = deserialMapFn;
/**
 * Option tag.
 *
 * Options are equivalent to nullable types. They can be either
 * {@link OptionTag.None}, which means that the value is `null`, or
 * {@link OptionTag.Some}, which means that a non-`null` value is present.
 */
var OptionTag;
(function (OptionTag) {
    /** Means that there's no value. */
    OptionTag[OptionTag["None"] = 0] = "None";
    /** Means that there's a value. */
    OptionTag[OptionTag["Some"] = 1] = "Some";
})(OptionTag = exports.OptionTag || (exports.OptionTag = {}));
/**
 * Takes a {@link Deserial} function of the given type and returns another
 * {@link Deserial} function that can read an option wrapped (nullable)
 * version of the same type.
 *
 * @typeParam T - {@link Deserial} output type
 * @param deserial function that takes {@link Readable} and returns `T`
 * @returns function that takes {@link Readable} and returns `T` or `null`
 */
function deserialOptionFn(deserial) {
    return function (source) {
        const tag = deserialUint8(source);
        switch (tag) {
            case OptionTag.None:
                return null;
            case OptionTag.Some:
                return deserial(source);
            default:
                throw new Error(`unsupported option tag: ${tag}`);
        }
    };
}
exports.deserialOptionFn = deserialOptionFn;
/**
 * Takes two {@link Deserial} functions of two given types: one for left
 * values and the other one for right values. Returns another {@link Deserial}
 * function that can read a tuple with left and right values of corresponding
 * types.
 *
 * @typeParam L - {@link Deserial} output type for left values
 * @typeParam R - {@link Deserial} output type for right values
 * @param deserialLeft function that takes {@link Readable} and returns `L`
 * @param deserialRight function that takes {@link Readable} and returns `R`
 * @returns function that takes {@link Readable} and returns a tuple of `L` and `R`
 */
function deserialTupleFn(deserialLeft, deserialRight) {
    return function (source) {
        const left = deserialLeft(source);
        const right = deserialRight(source);
        return [left, right];
    };
}
exports.deserialTupleFn = deserialTupleFn;
/**
 * Reads an unsigned 8-bit integer from the given {@link Readable}.
 *
 * @param source input stream
 * @returns number from 0 to 255
 */
function deserialUint8(source) {
    return source.read(1).readUInt8(0);
}
exports.deserialUint8 = deserialUint8;
/**
 * Reads an unsigned 32-bit integer from the given {@link Readable}.
 *
 * @param source input stream
 * @returns number from 0 to 4294967295
 */
function deserialUint32(source) {
    return source.read(4).readUInt32LE(0);
}
/**
 * Reads an unsigned 16-bit integer from the given {@link Readable}.
 *
 * @param source input stream
 * @returns number from 0 to 65535
 */
function deserialUint16(source) {
    return source.read(2).readUInt16LE(0);
}
/**
 * @param buffer Schema buffer
 * @param moduleVersion the version of the module (only needed for older versions of the schema).
 * @returns deserialized module of wasm file
 */
function deserialModuleFromBuffer(buffer, schemaVersion) {
    const bufferStream = new stream_1.PassThrough();
    bufferStream.end(buffer);
    if (buffer.readUInt16LE(0) === exports.VERSIONED_SCHEMA_PREFIX) {
        return deserialVersionedModule(bufferStream);
    }
    if (schemaVersion === undefined) {
        throw new Error('Supply a schema version to deserialize an unversioned schema');
    }
    switch (schemaVersion) {
        case types_1.SchemaVersion.V0:
            return {
                v: types_1.SchemaVersion.V0,
                value: deserialModuleV0(bufferStream),
            };
        case types_1.SchemaVersion.V1:
            return {
                v: types_1.SchemaVersion.V1,
                value: deserialModuleV1(bufferStream),
            };
        default:
            throw new Error('Unsupported module version');
    }
}
exports.deserialModuleFromBuffer = deserialModuleFromBuffer;
