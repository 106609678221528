"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createCredentialV1 = exports.getAccountAddress = exports.buildSignedCredentialForExistingAccount = exports.createUnsignedCredentialForExistingAccount = exports.createCredentialDeploymentTransaction = void 0;
const wasm = __importStar(require("@concordium/rust-bindings"));
const transactionExpiry_1 = require("./types/transactionExpiry");
const accountAddress_1 = require("./types/accountAddress");
const hash_1 = require("./hash");
const bs58check = __importStar(require("bs58check"));
const buffer_1 = require("buffer/");
/**
 * Generates the unsigned credential information that has to be signed when
 * deploying a credential. The randomness for the commitments that are part
 * of the transaction is also outputted, and it should be stored if the
 * commitments should be opened at a later point, i.e. if an attribute should
 * be revealed at a later point.
 * @param identity the identity to create a credential for
 * @param cryptographicParameters the global cryptographic parameters from the chain
 * @param threshold the signature threshold for the credential, has to be less than number of public keys
 * @param publicKeys the public keys for the account
 * @param credentialIndex the index of the credential to create, has to be in sequence and unused
 * @param revealedAttributes the attributes about the account holder that should be revealed on chain
 * @param address the account address, if the credential is to be deployed to an existing account
 * @returns the unsigned credential deployment information (for signing), and the randomness used
 */
function createUnsignedCredentialInfo(identity, cryptographicParameters, threshold, publicKeys, credentialIndex, revealedAttributes, address) {
    if (publicKeys.length > 255) {
        throw new Error('The number of keys is greater than what the transaction supports: ' +
            publicKeys.length);
    }
    const identityProvider = identity.identityProvider;
    const credentialInput = {
        ipInfo: identityProvider.ipInfo,
        arsInfos: identityProvider.arsInfos,
        global: cryptographicParameters,
        identityObject: identity.identityObject,
        randomness: {
            randomness: identity.randomness,
        },
        publicKeys,
        credentialNumber: credentialIndex,
        threshold,
        prfKey: identity.prfKey,
        idCredSec: identity.idCredSecret,
        revealedAttributes: revealedAttributes,
    };
    if (address) {
        credentialInput.address = address.address;
    }
    const unsignedCredentialDeploymentInfoString = wasm.generateUnsignedCredential(JSON.stringify(credentialInput));
    const result = JSON.parse(unsignedCredentialDeploymentInfoString);
    return result;
}
/**
 * Create a credential deployment transaction, which is the transaction used
 * when deploying a new account.
 * @param identity the identity to create a credential for
 * @param cryptographicParameters the global cryptographic parameters from the chain
 * @param threshold the signature threshold for the credential, has to be less than number of public keys
 * @param publicKeys the public keys for the account
 * @param credentialIndex the index of the credential to create, has to be in sequence and unused
 * @param revealedAttributes the attributes about the account holder that should be revealed on chain
 * @param expiry the expiry of the transaction
 * @returns the details used in a credential deployment transaction
 */
function createCredentialDeploymentTransaction(identity, cryptographicParameters, threshold, publicKeys, credentialIndex, revealedAttributes, expiry) {
    const unsignedCredentialInfo = createUnsignedCredentialInfo(identity, cryptographicParameters, threshold, publicKeys, credentialIndex, revealedAttributes);
    return {
        unsignedCdi: unsignedCredentialInfo.unsignedCdi,
        randomness: unsignedCredentialInfo.randomness,
        expiry: expiry,
    };
}
exports.createCredentialDeploymentTransaction = createCredentialDeploymentTransaction;
/**
 * Create an unsigned credential for an existing account. This credential has to be signed by
 * the creator before it can be deployed on the existing account.
 * @param identity the identity to create a credential for
 * @param cryptographicParameters the global cryptographic parameters from the chain
 * @param threshold the signature threshold for the credential, has to be less than number of public keys
 * @param publicKeys the public keys for the credential
 * @param credentialIndex the index of the credential to create, has to be in sequence and unused
 * @param revealedAttributes the attributes about the account holder that should be revealed on chain
 * @param address the account address to associated the credential with
 */
function createUnsignedCredentialForExistingAccount(identity, cryptographicParameters, threshold, publicKeys, credentialIndex, revealedAttributes, address) {
    return createUnsignedCredentialInfo(identity, cryptographicParameters, threshold, publicKeys, credentialIndex, revealedAttributes, address);
}
exports.createUnsignedCredentialForExistingAccount = createUnsignedCredentialForExistingAccount;
/**
 * Combines the unsigned credential information and the signatures to the signed credential
 * deployment information. This is the information that the account owner needs to be able
 * to deploy the credential to their account.
 * @param unsignedCredentialInfo the unsigned credential information
 * @param signatures the signatures on the unsigned credential information
 * @returns signed credential deployment information, used in an update credentials transaction to deploy it
 */
function buildSignedCredentialForExistingAccount(unsignedCredentialInfo, signatures) {
    const signedCredential = JSON.parse(wasm.getDeploymentInfo(signatures, JSON.stringify(unsignedCredentialInfo)));
    return signedCredential;
}
exports.buildSignedCredentialForExistingAccount = buildSignedCredentialForExistingAccount;
/**
 * Derives the account address from a credential id. This is the address of the
 * account that will be created by the credential deployment transaction containing
 * this credential id.
 * @param credId the credential id from a credential deployment transaction
 * @returns the account address
 */
function getAccountAddress(credId) {
    const hashedCredId = (0, hash_1.sha256)([buffer_1.Buffer.from(credId, 'hex')]);
    const prefixedWithVersion = buffer_1.Buffer.concat([buffer_1.Buffer.of(1), hashedCredId]);
    const accountAddress = new accountAddress_1.AccountAddress(bs58check.encode(prefixedWithVersion));
    return accountAddress;
}
exports.getAccountAddress = getAccountAddress;
/**
 * Creates a credential for a new account, using the version 1 algorithm, which uses a seed to generate keys and commitments.
 */
function createCredentialV1(input) {
    const rawRequest = wasm.createCredentialV1(JSON.stringify(input));
    let info;
    try {
        info = JSON.parse(rawRequest);
    }
    catch (e) {
        throw new Error(rawRequest);
    }
    return {
        expiry: transactionExpiry_1.TransactionExpiry.fromEpochSeconds(BigInt(input.expiry)),
        cdi: info,
    };
}
exports.createCredentialV1 = createCredentialV1;
