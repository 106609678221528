"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CcdAmount = void 0;
/**
 * Representation of a CCD amount.
 * The base unit of CCD is micro CCD, which is the representation
 * used on chain.
 */
class CcdAmount {
    constructor(microCcdAmount) {
        if (microCcdAmount < 0n) {
            throw new Error('A micro CCD amount must be a non-negative integer but was: ' +
                microCcdAmount);
        }
        else if (microCcdAmount > 18446744073709551615n) {
            throw new Error('A micro CCD amount must be representable as an unsigned 64 bit integer but was: ' +
                microCcdAmount);
        }
        this.microCcdAmount = microCcdAmount;
    }
    toJSON() {
        return this.microCcdAmount.toString();
    }
}
exports.CcdAmount = CcdAmount;
