"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountAddress = void 0;
const bs58check = __importStar(require("bs58check"));
const buffer_1 = require("buffer/");
/**
 * Representation of an account address, which enforces that it:
 * - Hash length exactly 50
 * - Is a valid base58 string
 */
class AccountAddress {
    constructor(address) {
        if (address.length !== 50) {
            throw new Error('The provided address ' +
                address +
                ' is invalid as its length was not 50');
        }
        try {
            this.decodedAddress = buffer_1.Buffer.from(bs58check.decode(address).slice(1));
            this.address = address;
        }
        catch (error) {
            throw error;
        }
    }
    static fromBytes(bytes) {
        return new AccountAddress(bs58check.encode(buffer_1.Buffer.concat([buffer_1.Buffer.of(1), bytes])));
    }
    toJSON() {
        return this.address;
    }
}
exports.AccountAddress = AccountAddress;
