"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataBlob = void 0;
const serializationHelpers_1 = require("../serializationHelpers");
/**
 * Representation of a transfer's memo or a registerData transaction's data, which enforces that:
 * - the byte length is <= 256
 */
class DataBlob {
    constructor(data) {
        if (data.length > 256) {
            throw new Error("A data blob's size cannot exceed 256 bytes");
        }
        this.data = data;
    }
    toJSON() {
        return (0, serializationHelpers_1.packBufferWithWord16Length)(this.data).toString('hex');
    }
}
exports.DataBlob = DataBlob;
