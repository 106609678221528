"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAccountTransactionHandler = exports.ConfigureDelegationHandler = exports.RegisterDataHandler = exports.UpdateCredentialsHandler = exports.UpdateContractHandler = exports.InitContractHandler = exports.DeployModuleHandler = exports.SimpleTransferWithMemoHandler = exports.SimpleTransferHandler = void 0;
const buffer_1 = require("buffer/");
const serialization_1 = require("./serialization");
const serializationHelpers_1 = require("./serializationHelpers");
const types_1 = require("./types");
const accountAddress_1 = require("./types/accountAddress");
const DataBlob_1 = require("./types/DataBlob");
const ccdAmount_1 = require("./types/ccdAmount");
class SimpleTransferHandler {
    getBaseEnergyCost() {
        return 300n;
    }
    serialize(transfer) {
        const serializedToAddress = transfer.toAddress.decodedAddress;
        const serializedAmount = (0, serializationHelpers_1.encodeWord64)(transfer.amount.microCcdAmount);
        return buffer_1.Buffer.concat([serializedToAddress, serializedAmount]);
    }
    deserialize(serializedPayload) {
        const toAddress = accountAddress_1.AccountAddress.fromBytes(buffer_1.Buffer.from(serializedPayload.read(32)));
        const amount = new ccdAmount_1.CcdAmount(serializedPayload.read(8).readBigUInt64BE(0));
        return {
            toAddress,
            amount,
        };
    }
}
exports.SimpleTransferHandler = SimpleTransferHandler;
class SimpleTransferWithMemoHandler extends SimpleTransferHandler {
    serialize(transfer) {
        const serializedToAddress = transfer.toAddress.decodedAddress;
        const serializedMemo = (0, serializationHelpers_1.encodeDataBlob)(transfer.memo);
        const serializedAmount = (0, serializationHelpers_1.encodeWord64)(transfer.amount.microCcdAmount);
        return buffer_1.Buffer.concat([
            serializedToAddress,
            serializedMemo,
            serializedAmount,
        ]);
    }
    deserialize(serializedPayload) {
        const toAddress = accountAddress_1.AccountAddress.fromBytes(buffer_1.Buffer.from(serializedPayload.read(32)));
        const memoLength = serializedPayload.read(2).readUInt16BE(0);
        const memo = new DataBlob_1.DataBlob(buffer_1.Buffer.from(serializedPayload.read(memoLength)));
        const amount = new ccdAmount_1.CcdAmount(serializedPayload.read(8).readBigUInt64BE(0));
        return {
            toAddress,
            memo,
            amount,
        };
    }
}
exports.SimpleTransferWithMemoHandler = SimpleTransferWithMemoHandler;
class DeployModuleHandler {
    getBaseEnergyCost(payload) {
        const cost = Math.round(payload.source.length / 10);
        return BigInt(cost);
    }
    serialize(payload) {
        if (payload.version === undefined) {
            // Assume the module has version and length embedded
            return payload.source;
        }
        else {
            // Assume the module is legacy build, which doesn't contain version and length
            const serializedWasm = (0, serializationHelpers_1.packBufferWithWord32Length)(payload.source);
            const serializedVersion = (0, serializationHelpers_1.encodeWord32)(payload.version);
            return buffer_1.Buffer.concat([serializedVersion, serializedWasm]);
        }
    }
    deserialize() {
        throw new Error('deserialize not supported');
    }
}
exports.DeployModuleHandler = DeployModuleHandler;
class InitContractHandler {
    getBaseEnergyCost(payload) {
        return payload.maxContractExecutionEnergy;
    }
    serialize(payload) {
        const serializedAmount = (0, serializationHelpers_1.encodeWord64)(payload.amount.microCcdAmount);
        const initNameBuffer = buffer_1.Buffer.from('init_' + payload.initName, 'utf8');
        const serializedInitName = (0, serializationHelpers_1.packBufferWithWord16Length)(initNameBuffer);
        const serializedModuleRef = payload.moduleRef.decodedModuleRef;
        const parameterBuffer = payload.param;
        const serializedParameters = (0, serializationHelpers_1.packBufferWithWord16Length)(parameterBuffer);
        return buffer_1.Buffer.concat([
            serializedAmount,
            serializedModuleRef,
            serializedInitName,
            serializedParameters,
        ]);
    }
    deserialize() {
        throw new Error('deserialize not supported');
    }
}
exports.InitContractHandler = InitContractHandler;
class UpdateContractHandler {
    getBaseEnergyCost(payload) {
        return payload.maxContractExecutionEnergy;
    }
    serialize(payload) {
        const serializedAmount = (0, serializationHelpers_1.encodeWord64)(payload.amount.microCcdAmount);
        const serializeIndex = (0, serializationHelpers_1.encodeWord64)(payload.address.index);
        const serializeSubindex = (0, serializationHelpers_1.encodeWord64)(payload.address.subindex);
        const serializedContractAddress = buffer_1.Buffer.concat([
            serializeIndex,
            serializeSubindex,
        ]);
        const receiveNameBuffer = buffer_1.Buffer.from(payload.receiveName, 'utf8');
        const serializedReceiveName = (0, serializationHelpers_1.packBufferWithWord16Length)(receiveNameBuffer);
        const parameterBuffer = payload.message;
        const serializedParameters = (0, serializationHelpers_1.packBufferWithWord16Length)(buffer_1.Buffer.from(parameterBuffer));
        return buffer_1.Buffer.concat([
            serializedAmount,
            serializedContractAddress,
            serializedReceiveName,
            serializedParameters,
        ]);
    }
    deserialize() {
        throw new Error('deserialize not supported');
    }
}
exports.UpdateContractHandler = UpdateContractHandler;
class UpdateCredentialsHandler {
    getBaseEnergyCost(updateCredentials) {
        const newCredentialsCost = updateCredentials.newCredentials
            .map((credential) => {
            const numberOfKeys = BigInt(Object.keys(credential.cdi.credentialPublicKeys.keys).length);
            return 54000n + 100n * numberOfKeys;
        })
            .reduce((prev, curr) => prev + curr, BigInt(0));
        const currentCredentialsCost = 500n * updateCredentials.currentNumberOfCredentials;
        return 500n + currentCredentialsCost + newCredentialsCost;
    }
    serialize(updateCredentials) {
        const serializedAddedCredentials = (0, serializationHelpers_1.serializeList)(updateCredentials.newCredentials, serializationHelpers_1.encodeWord8, ({ index, cdi }) => buffer_1.Buffer.concat([
            (0, serializationHelpers_1.encodeWord8)(index),
            (0, serialization_1.serializeCredentialDeploymentInfo)(cdi),
        ]));
        const serializedRemovedCredIds = (0, serializationHelpers_1.serializeList)(updateCredentials.removeCredentialIds, serializationHelpers_1.encodeWord8, (credId) => buffer_1.Buffer.from(credId, 'hex'));
        const serializedThreshold = (0, serializationHelpers_1.encodeWord8)(updateCredentials.threshold);
        return buffer_1.Buffer.concat([
            serializedAddedCredentials,
            serializedRemovedCredIds,
            serializedThreshold,
        ]);
    }
    deserialize() {
        throw new Error('deserialize not supported');
    }
}
exports.UpdateCredentialsHandler = UpdateCredentialsHandler;
class RegisterDataHandler {
    getBaseEnergyCost() {
        return 300n;
    }
    serialize(payload) {
        return (0, serializationHelpers_1.encodeDataBlob)(payload.data);
    }
    deserialize(serializedPayload) {
        const memoLength = serializedPayload.read(2).readUInt16BE(0);
        return {
            data: new DataBlob_1.DataBlob(buffer_1.Buffer.from(serializedPayload.read(memoLength))),
        };
    }
}
exports.RegisterDataHandler = RegisterDataHandler;
class ConfigureDelegationHandler {
    getBaseEnergyCost() {
        return 300n;
    }
    serialize(payload) {
        return (0, serializationHelpers_1.serializeConfigureDelegationPayload)(payload);
    }
    deserialize() {
        throw new Error('deserialize not supported');
    }
}
exports.ConfigureDelegationHandler = ConfigureDelegationHandler;
function getAccountTransactionHandler(type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
) {
    switch (type) {
        case types_1.AccountTransactionType.Transfer:
            return new SimpleTransferHandler();
        case types_1.AccountTransactionType.TransferWithMemo:
            return new SimpleTransferWithMemoHandler();
        case types_1.AccountTransactionType.DeployModule:
            return new DeployModuleHandler();
        case types_1.AccountTransactionType.InitContract:
            return new InitContractHandler();
        case types_1.AccountTransactionType.Update:
            return new UpdateContractHandler();
        case types_1.AccountTransactionType.UpdateCredentials:
            return new UpdateCredentialsHandler();
        case types_1.AccountTransactionType.RegisterData:
            return new RegisterDataHandler();
        case types_1.AccountTransactionType.ConfigureDelegation:
            return new ConfigureDelegationHandler();
        default:
            throw new Error('The provided type does not have a handler: ' + type);
    }
}
exports.getAccountTransactionHandler = getAccountTransactionHandler;
