"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isRemovalPendingChange = exports.isReduceStakePendingChange = exports.isStakePendingChangeV0 = exports.isStakePendingChangeV1 = exports.isBakerAccountV0 = exports.isBakerAccountV1 = exports.isBakerAccount = exports.isDelegatorAccount = void 0;
const isDelegatorAccount = (ai) => ai.accountDelegation !== undefined;
exports.isDelegatorAccount = isDelegatorAccount;
const isBakerAccount = (ai) => ai.accountBaker !== undefined;
exports.isBakerAccount = isBakerAccount;
const isBakerAccountV1 = (ai) => ai.accountBaker?.bakerPoolInfo !== undefined;
exports.isBakerAccountV1 = isBakerAccountV1;
const isBakerAccountV0 = (ai) => ai.accountBaker?.bakerPoolInfo === undefined;
exports.isBakerAccountV0 = isBakerAccountV0;
const isStakePendingChangeV1 = (spc) => spc.effectiveTime !== undefined;
exports.isStakePendingChangeV1 = isStakePendingChangeV1;
const isStakePendingChangeV0 = (spc) => spc.epoch !== undefined;
exports.isStakePendingChangeV0 = isStakePendingChangeV0;
const isReduceStakePendingChange = (spc) => spc.newStake !== undefined;
exports.isReduceStakePendingChange = isReduceStakePendingChange;
const isRemovalPendingChange = (spc) => !(0, exports.isReduceStakePendingChange)(spc);
exports.isRemovalPendingChange = isRemovalPendingChange;
